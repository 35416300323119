import React from 'react';

const Loader = () => {
    return (
      <>
        <div className="container-fluid">
          <div className="loader-sec">
            <div className="loader loader-7">
              <div className="line line1"></div>
              <div className="line line2"></div>
              <div className="line line3"></div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Loader;
